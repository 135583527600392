import React from 'react'
import "./Card.css"

import rightArrow from "../../assets/rightArrow.png"
const card = (props) => {
  return (
    <div className="program-card">
        {props.img}
        <span>{props.title}</span>
        <span>{props.description}</span>
        <div className="card-bottom">
            <button>Join Now</button>
            <img src={rightArrow} alt="" />
        </div>
    </div>
  )
}

export default card