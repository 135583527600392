import React from 'react'
import "./Programs.css";
import Card from "../Card/Card";
import data from "../../Programs-card-data"

const Programs = () => {
    return (
        <div id='program' className='programs'>
            <div className="programs-text">
                <span className='text-stroke'>EXPLORE OUR</span>
                <span>PROGRAMS</span>
                <span className='text-stroke'>TO SHAPE YOU</span>
            </div>
            <div className="programs-divs">
                {
                    data.map((program)=>{
                        console.log("hel");
                        return <Card
                            img={program.img}
                            title={program.title}
                            description={program.description} 
                        />
                    })
                }
                    
            </div>
        </div>
    )
}

export default Programs