import React from 'react'
import "./Hero.css";
import Header from '../Header/Header';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from "../../assets/hero_image_back.png";
import heart from "../../assets/heart.png";
import calories from "../../assets/calories.png"
import {motion}  from "framer-motion";
import NumberCounter from "number-counter";

export const Hero = () => {
    let transition = {type:"spring",duration:3};
    const mobile = window.innerWidth<=768?"123px":"238px";
    return (
        <div id='hero' className="hero">
            <div className="left-h">
                <div className="blur blur-h"></div>
                {/* header */}
                <Header />
                {/* the best add */}
                <div className="the-best-ad">
                    <motion.div
                        initial={{left:mobile}}
                        whileInView={{left:"8px"}}
                        transition={{...transition,type:"tween"}}
                    ></motion.div>
                    <span>The best Fitness club in the Town</span>
                </div>
                {/* hero heading */}
                <div className="hero-text">
                    <div>
                        <span className='text-stroke'>Shape </span>
                        <span>your</span>
                    </div>
                    <div>
                        <span>ideal body</span>
                    </div>
                    <div>In here we will help you to shape and build your ideal body and live up your life to fullest</div>
                </div>
                {/* figures */}
                <div className="figures">
                    <div>
                        <span>
                        <NumberCounter end={140} start={100} delay={4} preFix="+"/> 
                        </span>
                        <span>EXPERT COACHES</span>
                    </div>
                    <div>
                        <span>
                        <NumberCounter end={978} start={930} delay={5} preFix="+"/> 
                        </span>
                        <span>MEMBERS JOINED</span>
                    </div>
                    <div>
                        <span>
                        <NumberCounter end={50} start={1} delay={2} preFix="+"/> 
                        </span>
                        <span>FITNESS PROGRAMS</span>
                    </div>
                </div>
                {/* buttons */}
                <div className="hero-buttons">
                    <button>get started</button>
                    <button>learn more</button>
                </div>
            </div>
            <div className="right-h">
                <button className='buttons'>Join Now</button>
                <motion.div className="heart-rate"
                    initial={{right:"-1rem"}}
                    whileInView={{right:"4rem"}}
                    transition={transition}
                    >
                    <img src={heart} alt="" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>
                {/* hero images */}
                <img src={hero_image} alt="" className='hero-image' />
                <motion.img src={hero_image_back} alt="" className='hero-image-back' 
                    initial={{right:"11rem"}}
                    whileInView={{right:"20rem"}}
                    transition={{...transition}}
                />
                <motion.div 
                initial={{right:'32rem'}}
                whileInView={{right:"28rem"}}
                transition={{...transition}}
                className="calories">
                    <img src={calories} alt="" />
                    <div className="calories-text">
                        <span>Calories burned</span>
                        <span>220 kcal</span>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}
export default Hero;