import React from 'react';
import "./Testimonials.css";
import data from '../../testimonialsData';
import rightArrow from "../../assets/rightArrow.png";
import leftArrow from "../../assets/leftArrow.png";
import { motion } from "framer-motion"
import { useState } from 'react';
const Testimonials = () => {
    let transition = { type: "spring", duration: 3 };
    const [selected,setSlected] = useState(0);
    const dt =data[selected];
    // console.log(data.length);
    const changeInfo =(e)=>{
        let name = e.target.name;
        if(name=="next"){
            if(selected==data.length-1){
                setSlected(0)
            } else{
            setSlected(selected+1)
            }
        } else if(name=="previous"){
            
            if(selected==0){
                setSlected(data.length-1)
            } else{
            setSlected(selected-1)
            }
        }
    }
    return (
        <div id='testimonials' className="testimonials">
            <div className="left-t">
                <span>TESTIMONIALS</span>
                <div className='text-t'>
                    <span className='text-stroke'>WHAT THEY</span>
                    <span>SAY ABOUT US</span>
                    <motion.span
                    initial={{x:-100,opacity:0}}
                    animate={{x:0,opacity:1}}
                    transition={{...transition,duration:1}}
                    >
                        {dt.desription}
                    </motion.span>
                    <span>
                        <span>{dt.name}</span> - {dt.person}
                    </span>

                </div>
            </div>
            <div className="right-t">
                <div className="right-t-image">
                    <motion.div
                        initial={{ x: 100, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ ...transition, duration: 2 }}
                    ></motion.div>
                    <motion.div
                        initial={{ x: -200, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ ...transition, duration: 2 }}
                    ></motion.div>
                    <motion.img 
                    key={selected}
                    initial={{opacity:0,x:100}}
                    animate={{opacity:1,x:0}}
                    exit={{opacity:0,x:-100}}
                    transition
                    src={dt.image} alt="" />
                </div>
                <div className="arrows">
                    <img 
                    name="previous"
                    onClick={changeInfo}
                    src={leftArrow} alt="" />
                    <img 
                    name="next"
                    onClick={changeInfo}
                    src={rightArrow} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Testimonials