import React from 'react'
import "./Plans.css";
import planData from "../../plans-data";
import rightArrow from "../../assets/rightArrow.png"
import tick from "../../assets/whiteTick.png"
const Plans = () => {
    return (
        <div id='plans' className="plans-container">
            <div className="plans-header">
                <span className='text-stroke'>READY TO START
                </span>
                <span>YOUR JOURNEY
                </span>
                <span className='text-stroke'>NOW WITHUS
                </span>
            </div>
            <div className="plans-cards">
                {
                    planData.map((card)=>{
                        return <div className="card">
                            {card.icon}
                            <span>{card.name}</span>
                            <span>$ {card.price}</span>
                            <div className="featuers">
                            {
                                card.features.map((card_features)=>{
                                    return  <div>
                                            <img src={tick} alt="" />
                                            <span>{card_features}</span>
                                        </div>
                                })
                            }
                            </div>
                            <div className="benefits">
                                <span>See more benefits</span>
                                <img src={rightArrow} alt="" />
                            </div>
                            <button className='buttons' style={{width:"100%"}}>Join Now</button>
                        </div>
                    })
                }
            <div className="blur blur-p1"></div>
            <div className="blur blur-p2"></div>
            </div>
        </div>
    )
}

export default Plans