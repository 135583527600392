import image1 from "./assets/t-image1.png";
import image2 from "./assets/t-image2.jpg";
import image3 from "./assets/t-image3.jpg";
let data = [
    {
        image:image1,
        name:"MATHEW HENDRICKSON",
        person:"ENTREPRENEUR",
        desription:"I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!"
        
    },
    {
        image:image2,
        name:"JOHN KEVIN",
        person:"COACH",
        desription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi ipsam, ab itaque nam perferendis impedit sint"
        
    },
    {
        image:image3,
        name:"FRANKLIN",
        person:"CUSTOMER",
        desription:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem"
        
    }
]
export default data;