import React from 'react'
import { useRef } from 'react'
import "./Email-section.css"
import emailjs from "@emailjs/browser"

const Email_section = () => {
    let form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_p3rqibt',
         'template_19t5pc3',
          form.current,
         'MkBJlx65XcUQDexpD')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    return (
        <div className="email-section">
            <div className="left-e">
                <hr />
                <div>
                    <span className='text-stroke'>READY TO</span>
                    <span>
                        LEVEL UP</span>
                </div>
                <div>
                    <span>YOUR BODY</span>
                    <span className='text-stroke'>
                        WITH US?</span>
                </div>
            </div>
            <div className="right-e">
                <div className="email-container">
                    <form onSubmit={sendEmail} ref={form} action="">
                        <input type="text"
                        name='message'
                            placeholder='Enter your Email Address here'
                        />
                        <button type='submit' className="buttons">Join now</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Email_section