import React from 'react'
import "./Header.css";
import logo from "../.././assets/logo.png"
import { useState } from 'react';
import bars from "../../assets/bars.png"
import { Link } from 'react-scroll';
const Header = () => {
  const [button, setButtons] = useState(true);
  const pageselected = () => {
    setButtons(true)
  }
  const navbar = (
    <div className="header-menu">
         <ul>
           <li onClick={pageselected}><Link
           to='left-h'
           spy={true}
           smooth={true}
           >Home</Link></li>
           <li onClick={pageselected}><Link
           to='program'
           spy={true}
           smooth={true}
           >Programs</Link></li>
           <li onClick={pageselected}><Link
           to='whyus'
           spy={true}
           smooth={true}
           >Why us</Link></li>
           <li onClick={pageselected}><Link
           to='plans'
           spy={true}
           smooth={true}
           >Plans</Link></li>
           <li onClick={pageselected}><Link
           to='testimonials'
           spy={true}
           smooth={true}
           >Testimonials</Link></li>
         </ul>
       </div>
  );
  const barsd = (<div className='bars'
  onClick={()=>setButtons(false)}>
  <img src={bars} alt="" />
</div>)
const header = button?barsd:navbar
  console.log();
  console.log(navbar)
  return (
    <div className="header">
       <img className='logo' src={logo} alt="" />
       {window.innerWidth<=768?header:navbar}
     </div>
    

  )
}

export default Header